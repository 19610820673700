<template>
  <div>

    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'prizes-periods',
      resourceName: 'prizes-periods',
      singularName: 'prizes-period',
      searchFields: {
        title: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },

}
</script>
